import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import React from 'react';

const ConfirmDialog = ({
  title,
  text,
  open,
  onCancel,
  onConfirm,
  yesText,
  noText
}) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText> {text} </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="primary">
        {noText}
      </Button>
      {onConfirm && <Button onClick={onConfirm} color="primary" autoFocus>
        {yesText}
      </Button>}
    </DialogActions>
  </Dialog>
);

ConfirmDialog.defaultProps = {
  title: 'Are you sure?',
  onCancel: () => {},
  onConfirm: () => {},
  yesText: 'Yes',
  noText: 'No'
};

export default ConfirmDialog;
