import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {green, red} from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailsIcon from '@material-ui/icons/Details';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  chipSuccess: {
    background: green[400],
    color: 'white'
  },
  chipProgress: {
    // background: red[400],
    // color: 'white',
    width: '58px',
    textAlign: 'center'
  },
  deleteIcon: {
    cursor: 'pointer',
    color: '#aaa',
    '&:hover': {
      color: red[300]
    },
    '&:active': {
      color: red[400]
    }
  }
}));

const XTable = (props) => {
  const {
    className,
    items,
    decPriority,
    incPriority,
    deleteItem,
    showDetails,
    pagination,
    isLoading,
    displayTag,
    ...rest
  } =
    props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  {displayTag && <TableCell>Tag</TableCell>}
                  {displayTag && <TableCell>Username</TableCell>}
                  <TableCell>Status</TableCell>
                  <TableCell>Synced</TableCell>
                  <TableCell>Filtered</TableCell>
                  <TableCell>Missing</TableCell>
                  <TableCell>Error</TableCell>
                  <TableCell>Total</TableCell>
                  {displayTag && <TableCell>Priority</TableCell>}
                  {/* <TableCell>Creation date</TableCell> */}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow className={classes.tableRow} hover key={item.tid}>
                    <TableCell>
                      {item.name}
                    </TableCell>
                    {displayTag && <TableCell>{item.tag}</TableCell>}
                    {displayTag && <TableCell>{item.tag ? window.atob(item.tag) : ''}</TableCell>}
                    <TableCell>
                      <Chip
                        size="small"
                        className={
                          item.done === item.total
                            ? classes.chipSuccess
                            : classes.chipProgress
                        }
                        label={
                          item.done === item.total
                            ? 'Completed'
                            : `${Math.round((100 * item.done) / item.total)}%`
                        }
                      />
                    </TableCell>
                    {/* <TableCell>{item.synced_count !== item.product_count ? `${item.synced_count} /` : ''}{item.product_count}</TableCell> */}

                    <TableCell>
                      <a href={'/hunts/' + item.tid}>
                        {item.done - (item.filtered || 0) - (item.missing || 0) - (item.skipped || 0) - (item.fail || 0)}
                      </a>
                    </TableCell>
                    <TableCell>{item.filtered}</TableCell>
                    <TableCell>{item.missing}</TableCell>
                    <TableCell>{item.fail}</TableCell>
                    <TableCell>{item.total}</TableCell>
                    {/* <TableCell>
                      {moment(item.created_at).format('DD/MM/YYYY')}
                    </TableCell> */}

                    {displayTag && <TableCell>{item.priority}</TableCell>}
                    <TableCell>
                      <DeleteIcon
                        className={classes.deleteIcon}
                        onClick={() => deleteItem(item)}
                      />
                      {item.query && <DetailsIcon
                        className={classes.deleteIcon}
                        onClick={() => showDetails(item)}
                      />}

                      {displayTag && <ArrowUpwardIcon
                        className={classes.deleteIcon}
                        onClick={() => incPriority(item)}
                      />}
                      {displayTag && <ArrowDownwardIcon
                        className={classes.deleteIcon}
                        onClick={() => decPriority(item)}
                      />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        {isLoading && <CircularProgress size={24}/>}
        <TablePagination component="div" {...pagination} />
      </CardActions>
    </Card>
  );
};

XTable.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired
};

export default XTable;
