import {Button, Card, CardActions, CardContent, Select, TextField} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {green, red} from '@material-ui/core/colors';
import {makeStyles} from '@material-ui/styles';
import api from 'api-snej';
import clsx from 'clsx';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {CheckBox, CheckBoxOutlineBlankOutlined} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: '20px'
  },
  fieldContainerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  urlContainerWrapper: {
    display: "flex",
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center"
  },
  fieldContainer1: {
    flex: 1,
    padding: "5px 10px 5px 0"
  },
  fieldContainerMid: {
    flex: 1,
    padding: "5px 10px 5px 10px"
  },
  fieldContainer2: {
    flex: 1,
    padding: "5px 0 5px 10px"
  },
  CheckboxFieldContainer: {
    flex: 1,
    padding: "5px 0 5px 10px",
    display: "flex"
  },
  regionSelectBox: {
    width: "100%",
    padding: '1px 10px'
  },
  filterTextBox: {
    width: "100%"
  },
  regionSelectBoxLabel: {
    opacity: 0.5,
    fontSize: "0.8em",
    padding: '1px'
  },
  actions: {
    padding: '20px',
    justifyContent: 'center'
  },
  loadingCircle: {
    marginLeft: '8px',
  },
  chipSuccess: {
    background: green[400],
    color: 'white'
  },
  chipProgress: {
    // background: red[400],
    // color: 'white',
    width: '58px',
    textAlign: 'center'
  },
  deleteIcon: {
    cursor: 'pointer',
    color: '#aaa',
    '&:hover': {
      color: red[300]
    },
    '&:active': {
      color: red[400]
    }
  },
  domainSelect: {
    marginLeft: '35px',
    width: '120px'
  },
  selectedFile: {
    flex: '0 0 auto',
    textAlign: 'center',
    padding: '20px 30px',
    color: 'white',
    position: 'relative',
    backgroundColor: 'gray',
    '&.loading': {
      backgroundColor: 'gray'
    },
    '&.fail': {
      backgroundColor: 'red'
    },
    '&.success': {
      backgroundColor: 'green'
    }
  },
  selectedFileTitle: {
    fontWeight: 'bold'
  },
  selectedFileSubtitle: {
    marginTop: '3px',
    fontSize: '12px'
  },
  dropbox: {
    marginTop: '10px'
  },
  customAsins: {
    marginTop: '15px'
  },
  urlHint: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'start',
    height: '35px',
    color: 'rgb(16,19,118)',
    marginLeft: '10px'
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const domainOptions = [
  {value: 'CA', domain: 'CA', label: 'Canada'},
  {value: 'FR', domain: 'FR', label: 'France'},
  {value: 'DE', domain: 'DE', label: 'Germany'},
  {value: 'ES', domain: 'ES', label: 'Spain'},
  {value: 'IT', domain: 'IT', label: 'Italy'},
  {value: 'GB', domain: 'UK', label: 'United Kingdom'},
  {value: 'US', domain: 'US', label: 'United States'},
  {value: 'MX', domain: 'MX', label: 'Mexico'},
];
const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const asinReg = /^[A-Z0-9]{10}$/;

const HunterTable = (props) => {
  const {className, onCreate, ...rest} = props;

  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  const [source, setSource] = useState('US');
  const [destination, setDestination] = useState('CS');

  const [profit, setProfit] = useState(20);
  const [roi, setRoi] = useState(30);
  const [exchangeRate, setExchangeRate] = useState(1.44);
  const [destOutOfStock, setDestOutOfStock] = useState(false);
  const [filterPrime, setFilterPrime] = useState(false);
  const [destFbaFee, setDestFbaFee] = useState(false);

  const [query, setQuery] = useState('');
  const [name, setName] = useState('');
  const [domain, setDomain] = useState('CA');
  const [count, setCount] = useState(1000);
  const [filter, setFilter] = useState(
    localStorage.getItem('huntfilter') || '0.8 * (ca  - us) > 0'
  );
  const [filterRoi, setFilterRoi] = useState(
    localStorage.getItem('huntfilter') || '0.8 * (ca  - us) > 0'
  );
  const [createLoading, setCreateLoading] = useState(false);
  const [restartLoading, setRestartLoading] = useState(false);
  const [customAsins, setCustomAsins] = useState();
  const [fileReadProg, setFileReadProg] = useState([]);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone();

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const create = () => {
    setCreateLoading(true);
    localStorage.setItem('huntfilter', filter);
    let cName = moment().format('L');
    if (!query || query === "") {
      enqueueSnackbar("Keepa URL is required", {variant: 'error'});
      setCreateLoading(false)
      return;
    }
    if (name) {
      cName = `${cName} - ${name}`;
    }
    let modifiedCount = count;
    if (count > 1000) {
      modifiedCount = 1000;
      console.log('Maximum count: ' + modifiedCount);
    }
    let modifiedQuery = query;
    if (modifiedQuery.indexOf('perPage') > 0) {
      modifiedQuery = modifiedQuery.replace(/%2C%22perPage%22%3A[0-9]*/, '%2C%22perPage%22%3A100');
      console.log('removing perPage from query: ' + modifiedQuery);
    }
    let exp_profit = getProfitExp();
    let exp_roi = getRoiExp();
    let generatedFilter = generateFilter();
    let generatedFilterRoi = generateFilterRoi();
    console.log(generatedFilter)
    console.log(generatedFilterRoi)
    // const extraAsin = customAsins.split(/[\s,]+/).map(s => s.trim()).filter(s => asinReg.test(s));
    api
      .post(`/tasks/prices`, {
        name: cName,
        count: modifiedCount,
        domain,
        query: modifiedQuery,
        profit,
        filter: generatedFilter,
        filter_roi: generatedFilterRoi,
        filter_prime: filterPrime,
        exp_profit: exp_profit,
        exp_roi: exp_roi,
        source,
        destination,
        tag: btoa(localStorage.getItem('jens_username')),
        extra_asins: customAsins || null
      })
      .then(() => enqueueSnackbar(`Hunt created`, {variant: 'info'}))
      .then(onCreate)
      .then(() => {
        setQuery('');
      })
      .catch((e) => enqueueSnackbar(e.message, {variant: 'error'}))
      .finally(setCreateLoading);
  };

  const restartWorkers = () => {
    setRestartLoading(true);
    api
      .post(`/restartworkers`, {})
      .then(() => enqueueSnackbar(`Workers Restarted`, {variant: 'info'}))
      .catch((e) => enqueueSnackbar(e.message, {variant: 'error'}))
      .finally(setRestartLoading);
  }
  const getProfitExp = () => {
    if (destFbaFee) {
      return `(1 - (ref_fee/100)) * ${destination} - ${exchangeRate} * ${source} - fba_fee`
    } else {
      return `(1 - (ref_fee/100)) * ${destination} - ${exchangeRate} * ${source}`
    }
  }
  const getRoiExp = () => {
    return `((${getProfitExp()}) / (${source} * ${exchangeRate}))`
  }
  const generateFilterRoi = () => {
    let generatedFilter = `${getRoiExp()} > (${roi / 100})`;
    if (destOutOfStock) {
      generatedFilter = `${generatedFilter} or ${destination} == 0`
      generatedFilter = `(${generatedFilter}) and ${source} > 0`;
    }
    setFilterRoi(generatedFilter);
    return generatedFilter;
  }
  const generateFilter = () => {
    let generatedFilter = `${getProfitExp()} > ${profit}`
    if (destOutOfStock) {
      generatedFilter = `${generatedFilter} or ${destination} == 0`
      generatedFilter = `(${generatedFilter}) and ${source} > 0`;
    } else {
      generatedFilter = `${generatedFilter} and ${source} > 0`;
    }
    setFilter(generatedFilter);
    return generatedFilter;
  }

  useEffect(() => {
    const set = new Set();
    let prog = acceptedFiles.map(() => 'loading');
    setFileReadProg(prog);

    const p = acceptedFiles.map((file, idx) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        let failed = false;
        reader.onload = function (e) {
          const contents = e.target.result;
          const parts = contents.split(/[\s,]+/);
          for (let i = 0; i < parts.length; i++) {
            if (parts[i].trim().length === 0) {
              continue;
            }
            if (!asinReg.test(parts[i].trim())) {
              console.error('Bad ASIN in file', file.name, parts[i]);
              prog[idx] = 'fail';
              setFileReadProg([...prog]);
              failed = true;
              return;
            }
          }
          for (let i = 0; i < parts.length; i++) {
            set.add(parts[i].trim());
          }
        };
        reader.onloadend = () => {
          if (!failed) {
            prog[idx] = 'success';
            setFileReadProg([...prog]);
          }
          resolve();
        };
        reader.readAsText(file);
      });
    });
    Promise.all(p).then(() => {
      const stuff = Array.from(set).join(' ');
      setCustomAsins(stuff);
    });
  }, [acceptedFiles]);

  const isAdmin = () => {
    return localStorage.getItem('jens_username') === 'admin'
  };
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>

        <div className={classes.fieldContainerWrapper}>
          <div className={classes.fieldContainer1}>
            <div id="source-reg-id" className={classes.regionSelectBoxLabel}>Source
            </div>
            <Select
              native
              labelId="source-reg-id"
              margin="dense"
              key={'src'}
              variant="outlined"
              value={source}
              className={classes.regionSelectBox}
              onChange={e => {
                setSource(e.target.value);
              }}>
              {domainOptions.map(o => (
                <option key={o.value} value={o.value} style={{fontSize: '1.3em'}}>
                  {o.domain} ({o.label})
                </option>
              ))}
            </Select>

          </div>
          <div className={classes.fieldContainer2}>
            <div id="dest-reg-id" className={classes.regionSelectBoxLabel}>Destination
            </div>
            <Select
              native
              labelId="dest-reg-id"
              margin="dense"
              key={'dst'}
              variant="outlined"
              value={destination}
              className={classes.regionSelectBox}
              onChange={e => {
                setDestination(e.target.value);
              }}>
              {domainOptions.map(o => (
                <option key={o.value} value={o.value} style={{fontSize: '1.3em'}}>
                  {o.domain} ({o.label})
                </option>
              ))}
            </Select>
          </div>
        </div>

        <div className={classes.urlContainerWrapper}>
          <div className={classes.fieldContainer1}>
            <TextField
              fullWidth
              // helperText="Please specify the first name"
              label="Keepa URL"
              margin="dense"
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              variant="outlined"
            />
          </div>

          <div className={classes.urlHint}>
            <div>
              <a href="https://keepa.com/#!finder" target="_blank">Go to keepa product finder</a>
            </div>
          </div>
        </div>
        <div className={classes.fieldContainerWrapper}>
          <div className={classes.fieldContainer1}>
            <TextField
              // helperText="Please specify the first name"
              label="Profit(Dest Currency)"
              className={classes.filterTextBox}
              margin="dense"
              onChange={(e) => setProfit(e.target.value)}
              value={profit}
              type="number"
              variant="outlined"
            />
          </div>

          <div className={classes.fieldContainerMid}>
            <TextField
              // helperText="Please specify the first name"
              label="ROI(%)"
              className={classes.filterTextBox}
              margin="dense"
              onChange={(e) => setRoi(e.target.value)}
              value={roi}
              variant="outlined"
            />
          </div>
          <div className={classes.fieldContainer2}>
            <TextField
              // helperText="Please specify the first name"
              label="Exchange Rate(Src to Dest)"
              className={classes.filterTextBox}
              margin="dense"
              onChange={(e) => setExchangeRate(e.target.value)}
              value={exchangeRate}
              type="number"
              variant="outlined"
            />
          </div>
        </div>

        <div className={classes.fieldContainerWrapper}>
          <div className={classes.fieldContainer1}>
            <TextField
              // helperText="Please specify the first name"
              label="Name"
              className={classes.filterTextBox}
              margin="dense"
              onChange={(e) => setName(e.target.value)}
              value={name}
              variant="outlined"
            />
          </div>
          {/*<div className={classes.fieldContainerMid}>*/}
          {/*  <TextField*/}
          {/*    // helperText="Please specify the first name"*/}
          {/*    type="number"*/}
          {/*    className={classes.filterTextBox}*/}
          {/*    label="Count"*/}
          {/*    margin="dense"*/}
          {/*    onChange={(e) => setCount(e.target.value)}*/}
          {/*    value={count}*/}
          {/*    variant="outlined"*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className={classes.fieldContainerWrapper}>
          <div className={classes.CheckboxFieldContainer} onClick={e => {
            setFilterPrime(!filterPrime);
            generateFilter();
          }}>
            {filterPrime ? <CheckBox/> : <CheckBoxOutlineBlankOutlined/>}
            <div style={{marginLeft: 3, marginTop: 2}}>Source: Prime Only</div>
          </div>

          <div className={classes.CheckboxFieldContainer} onClick={e => {
            setDestOutOfStock(!destOutOfStock);
            generateFilter();
          }}>
            {destOutOfStock ? <CheckBox/> : <CheckBoxOutlineBlankOutlined/>}
            <div style={{marginLeft: 3, marginTop: 2}}>Destination: Include Out Of Stock</div>
          </div>

          <div className={classes.CheckboxFieldContainer} onClick={e => {
            setDestFbaFee(!destFbaFee);
            generateFilter();
          }}>
            {destFbaFee ? <CheckBox/> : <CheckBoxOutlineBlankOutlined/>}
            <div style={{marginLeft: 3, marginTop: 2}}>Include FBA Fulfillment Fee</div>
          </div>

        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        {isAdmin() && <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={restartWorkers}
          disabled={restartLoading}>
          Restart Workers
          {restartLoading && <CircularProgress className={classes.loadingCircle} size={18}/>}
        </Button>}
        <div className={classes.urlContainerWrapper}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={create}
            disabled={createLoading}>
            Search
            {createLoading && <CircularProgress className={classes.loadingCircle} size={18}/>}
          </Button>
          <div className={classes.urlHint}>
            <a href="https://youtu.be/OSzkLjPd4IY" target="_blank">How to Search: Quick Tutorial</a>
          </div>
        </div>
      </CardActions>
    </Card>
  )
    ;
};

HunterTable.propTypes = {
  className: PropTypes.string
};

export default HunterTable;
